.device-params {
  display: flex;
  flex-direction: column;
  line-height: 18px;
  gap: 14px;
  padding: 0 54px;
}
.device-field {
  display: flex;
  margin-top: 14px;
}
.device-title {
  width: 160px;
  min-width: 160px;
  color: var(--grey);
  line-height: 18px;
}
.device-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  margin-top: 12px;
  padding-right: 40px;
}
.device-action {
  width: 222px;
}
.devices-modal {
  padding: 16px 0 40px 0;
  max-width: 764px;
  position: relative;
}
.devices-modal header {
  line-height: 28px;
  margin-bottom: 16px;
  padding: 0 60px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
}
.devices {
  width: 100%;
  margin-top: 12px;
}
.devices-filters {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  padding: 0 40px 0 60px;
  margin-bottom: 20px;
}
.devices-filters button {
  min-width: 40px;
}
.devices-filter {
  max-width: 232px;
}
.devices-clear-filters {
  margin: 0 auto 8px 60px;
}
.devices-action {
  width: 222px;
  margin: 25px auto 0 auto;
}
.close {
  margin: 0 40px 0 auto;
  width: 24px;
  height: 24px;
}
.devices-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.devices-table td:nth-child(1) {
  width: 40px;
  padding-left: 60px;
}
.devices-table td:last-child {
  padding-right: 40px;
}
