.body {
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  overflow: visible;
}
.paper {
  flex: none !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 40px;
  overflow: visible;
  margin-bottom: 4px;
}
.header {
  display: flex;
}
.header h3 {
  margin: 0 20px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 40px;
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}
.fields-wrapper {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.fields-row {
  display: flex;
  gap: 20px;
}
.field {
  flex: 0.25 !important;
}
