.wrapper {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 30px;
}
.wrapper header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--grey-dark);
  font-size: 16px;
  font-family: var(--font-regular);
  font-weight: var(--font-weight-s);
}
.profile {
  display: flex;
  align-items: center;
}
.profile-link {
  margin-right: 12px;
  font-weight: var(--font-weight-s);
  font-family: var(--font-regular);
}
