.wrapper {
  position: relative;
  max-width: 640px;
  padding: 44px 40px 35px 40px;
}
.close {
  position: absolute !important;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px !important;
}
.wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.header-title {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
  line-height: 40px;
}
.wrapper header button {
  width: 160px;
}
.info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.info-ids {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info-ids span {
  color: var(--grey);
}
.info-count {
  display: flex;
  flex-direction: column;
}
.count {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
  line-height: 24px;
  text-align: right;
}
.info-count span {
  font-size: var(--font-size-xs);
}
.data {
  display: flex;
  gap: 40px;
  margin: 20px 0;
}
.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.row {
  display: flex;
  gap: 10px;
}
.key {
  width: 120px;
  color: var(--grey);
  word-break: break-all;
  line-height: 18px;
}
.actions {
  display: flex;
  gap: 20px;
}
.actions button {
  flex: 1;
}
