.table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 210px);
  padding: 0 40px 0 40px;
}
.table td:nth-child(4),
.table td:nth-child(5) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.header {
  flex: 0;
}
.create-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 25px 40px 40px 40px;
}
.create-wrapper header {
  line-height: 60px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  margin-bottom: 28px;
}
.create-close {
  position: absolute !important;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}
.create-field {
  margin-bottom: 10px;
}
.create-wrapper footer {
  margin-top: 8px;
  border-top: 1px solid #c9cdd8;
}
.create-wrapper footer button {
  margin-top: 36px;
  width: 100%;
}
.created-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  transition: all 200ms ease;
  flex: 1;
  min-height: 38px;
  max-height: 38px;
  padding: 0 16px;
  font-family: inherit;
  font-size: inherit;
  outline: none;
}

.created-field {
  display: flex;
  flex-direction: column;
}
.add-fields {
  border-bottom: 1px;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.create-value {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 267px;
  max-height: 40px;
}
