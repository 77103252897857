.table {
  padding: 0 40px 12px 40px;
  min-height: 220px;
}
.table td:nth-child(9),
.table td:nth-child(10) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.multiselect-roles {
  flex: none;
  width: 440px;
}
