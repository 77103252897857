.body {
  display: flex;
  padding: 16px;
  height: calc(100vh - 144px);
  gap: 10px;
}
.table {
  flex: 1;
  padding: 12px 0;
}
.table td:nth-child(2),
.table td:nth-child(3) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.form-wrapper {
  flex: 1;
  min-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 22px 32px 32px;
  background-color: var(--white-med);
  border-radius: 12px;
}
.form-wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 60px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-ms);
  margin: 16px 0 0px 0;
}
.form-wrapper header:first-child {
  font-size: var(--font-size-m);
}
.filters-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 40px 20px 40px;
  margin-bottom: 10px;
  max-height: 104px;
}
.filters-wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
}
.form-wrapper hr {
  max-height: 1px;
  background-color: var(--grey);
  margin: 18px 0 0 0;
}
.create-close {
  position: absolute !important;
  top: 24px;
  right: 14px;
  height: 14px;
}
.models-box {
  width: 100%;
  overflow-y: auto;
}
.input-field {
  flex: none;
  margin-bottom: 5px;
}
.models-checkbox-table {
  padding-right: 15px;
  overflow-y: auto;
}
.models-checkbox-table thead {
  display: none;
}
.models-checkbox-table tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.models-checkbox-table td {
  display: flex;
  align-items: center;
}
