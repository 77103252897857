.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 14px 40px 58px;
  max-width: 528px;
}
.wrapper header {
  line-height: 60px;
  margin-bottom: 22px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
}
.close {
  position: absolute !important;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px !important;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.column:first-child {
  padding-right: 18px;
  border-right: 1px solid var(--grey);
}
.column:last-child {
  padding-left: 18px;
}
.body {
  display: flex;
}
.loader {
  text-align: center;
  height: 80px;
}

.inProgress {
  background: linear-gradient(90deg, transparent, var(--accent), transparent, var(--accent));
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
  transform: translate3d(0, 0, 0);
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
