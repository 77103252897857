.wrapper {
  position: relative;
  flex: initial !important;
  display: flex;
  flex-direction: column;
  padding: 24px 40px 40px;
  width: 360px;
}
.close {
  position: absolute !important;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px !important;
}
.header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header h3 {
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  margin-bottom: 16px;
}

.params-field {
  min-width: 346px;
  margin-bottom: 8px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--grey-med-light);
  padding-top: 36px;
  margin-top: 14px;
}
.footer button {
  flex: 1;
}
