.table {
  padding: 0 40px 12px 40px;
}
.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(3),
.table td:nth-child(4) {
  width: 24%;
}
.table td:nth-child(5),
.table td:nth-child(6),
.table td:nth-child(7) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
