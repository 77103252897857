.wrapper {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 40px;
  margin-bottom: 24px;
  border-radius: var(--radius-s);
  box-shadow: var(--shadow);
}
.header h3 {
  margin: 0 16px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
}
.back {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit {
  width: 222px;
  margin-left: auto;
}
.form {
  display: grid;
  grid-template-areas:
    'name name name name'
    'description description description description'
    'description description description description'
    'protocol server port credentials';
  row-gap: 20px;
  column-gap: 20px;
  padding: 0 40px;
  margin-bottom: 28px;
}
.name {
  grid-area: name;
}
.description {
  grid-area: description;
}
.server {
  grid-area: server;
}
.port {
  grid-area: port;
}
.protocol {
  grid-area: protocol;
}
.credentials {
  grid-area: credentials;
}
.description label {
  font-weight: var(--font-weight-s);
}
/* VEHICLES LIST */
.filters-header {
  font-weight: var(--font-weight-m);
  padding: 0 40px;
  margin-bottom: 16px;
}
.filters {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 40px;
  margin-bottom: 20px;
}
.filters-btn {
  top: 10px;
}
.list {
  display: flex;
  gap: 40px;
  padding: 0 40px;
  margin-bottom: 20px;
}
.list-column {
  flex: 1;
  border-radius: 12px;
  padding-top: 20px;
  background: var(--light-grey);
}
.list-column header {
  font-weight: var(--font-weight-m);
  padding: 0 20px;
  margin-bottom: 4px;
}
.list-table {
  height: 270px;
}
.list-table th {
  white-space: nowrap;
}
.list-table td:nth-child(1) {
  width: 1%;
  padding-left: 20px;
  padding-right: 8px;
}
.list-table tbody tr td:nth-child(2) {
  width: 39%;
}
.list-table th:last-child,
.list-table td:last-child {
  padding-right: 40px;
}
.list-table tr button {
  opacity: 0;
}
.list-table tr:hover button {
  opacity: 1;
}
.list-table tr button:hover path {
  fill: var(--light-black) !important;
}
.list-table tbody tr:hover {
  background-color: var(--medium-grey) !important;
}
.list-table svg {
  width: 16px;
}
