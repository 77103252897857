.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: var(--shadow);
  padding: 20px 40px;
  border-radius: var(--radius-s);
}
.header-actions {
  display: flex;
}
.header h3 {
  margin: 0 15px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 40px;
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}
.header-tabs {
  display: flex;
  align-items: center;
}
.tabs button {
  width: 180px;
}
.option-autosave {
  margin-left: auto;
  text-align: right;
  color: var(--dark-grey);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-s);
  font-family: var(--font-regular);
}

.body {
  flex: 1;
  display: flex;
  padding-top: 12px;
  padding-bottom: 24px;
}
.params {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 280px;
  padding: 0 40px;
  margin-top: 12px;
  border-right: 1px solid var(--grey);
}
.params-field {
  flex: 0 !important;
  width: 280px;
}
.params-field-date {
  width: 268px !important;
}
.options {
  flex: 1;
}
.options-table {
  width: 100%;
}
.options-table tbody tr {
  height: 60px;
}
.options-table td:nth-child(2) {
  width: 160px;
}
.options-table td:nth-child(3),
.options-table td:nth-child(4) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.options-table th:first-child,
.options-table td:first-child {
  padding-left: 40px;
}
.options-table th:last-child,
.options-table td:last-child {
  width: 1%;
  padding: 0 40px 0 12px !important;
}
