.header {
  display: flex;
  flex-direction: column;
  padding: 10px 40px 20px 40px;
  margin-bottom: 10px;
  max-height: 165px;
}
.header header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
  margin-bottom: 10px;
}

.filters {
  display: grid;
  grid-template-areas:
    'vehicleId vin plateNumber esn iccid'
    'clear empty empty empty apply';
  grid-template-rows: repeat(2, 40px);
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 14px;
}
.clear {
  grid-area: clear;
  justify-content: flex-start;
}
.apply {
  grid-area: apply;
}
.table {
  padding: 0 40px;
}
.table td:nth-child(10),
.table td:nth-child(11),
.table td:nth-child(12),
.table td:nth-child(13) {
  width: 1%;
  padding: 0 0 0 12px !important;
}

.options-values-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 25px 40px;
}
.options-values-wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 60px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
}
.options-values-close {
  position: absolute !important;
  top: 24px;
  right: 14px;
  height: 14px;
}
.options-values-table {
  margin-bottom: 28px;
}
.options-values-table td:nth-child(3),
.options-values-table td:nth-child(4) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.options-values-table td:nth-child(2) {
  text-align: left;
  width: 80px;
}
