.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  box-shadow: var(--shadow);
  padding: 20px 40px;
  border-radius: var(--radius-s);
}
.header h3 {
  margin: 0 16px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 40px;
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 40px;
}
.form-row {
  display: flex;
  gap: 20px;
}
.form-field {
  flex: 1;
}
