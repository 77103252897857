.table {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 210px);
  padding: 0 40px 0 40px;
}
.options-values-table thead,
.table thead {
  display: none;
}
.table td:nth-child(1) {
  padding: 0;
}
.table td:nth-child(1) button {
  width: 36px;
}
.load-icon path {
  fill: none !important;
}
.table td:nth-child(1),
.table td:nth-child(3),
.table td:nth-child(4),
.table td:nth-child(5) {
  width: 1%;
}
.table td:nth-child(3),
.table td:nth-child(4),
.table td:nth-child(5) {
  padding: 0 0 0 12px !important;
}
.table td:nth-child(2) {
  width: 90%;
}

.model-loader {
  height: 40px;
}

.form-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 22px 32px 32px;
  background-color: var(--white-med);
  border-radius: 12px;
}
.form-wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 50px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-ms);
  margin-top: 16px;
}
.item-id {
  margin-bottom: 10px;
}
.item-id span:first-child {
  color: var(--grey);
}
.form-wrapper header:first-child {
  font-size: var(--font-size-m);
}
.form {
  display: flex;
  gap: 25px;
}
.form-wrapper header button,
.form button {
  width: 155px;
}
.form-wrapper hr {
  max-height: 1px;
  background-color: var(--grey);
  margin: 38px 0 0 0;
}

.options-values-table {
  overflow-y: auto;
}
.options-values-table td:nth-child(3),
.options-values-table td:nth-child(4) {
  width: 1%;
  padding: 0 8px !important;
}
.options-values-table td:nth-child(2) {
  text-align: left;
  width: 80px;
}

.value-form-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 16px 40px 40px 40px;
}
.value-form-wrapper header {
  line-height: 29px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  margin-bottom: 24px;
}
.value-form-close {
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: -24px;
}
.value-form-field:nth-child(2) {
  z-index: 98;
}
.value-form-field {
  margin-bottom: 20px;
}
.value-form-field-radio label {
  line-height: 20px;
}
.value-form-wrapper footer {
  margin-top: 4px;
}
.value-form-wrapper footer button {
  width: 100%;
}
