.wrapper {
  grid-area: nav;
  display: flex;
  flex-direction: column;
}
.item {
  display: flex;
  align-items: center;
  width: 124px;
  height: 60px;
  padding: 0 30px;
  border-top: 1px solid var(--grey);
  background-color: transparent;
}
.item__active {
  background-color: var(--dark-grey);
  color: var(--white);
}
.item:hover {
  background-color: var(--grey);
  color: var(--white);
}
