.body {
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.paper {
  flex: none !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 40px 20px 40px;
  overflow: visible;
  margin-bottom: 24px;
}

.header {
  display: flex;
}

.header h3 {
  margin: 0 20px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 40px;
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0 !important;
}
.btn-wrapper button {
  width: 184px;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}

.options-values-table td:nth-child(2) {
  width: 15%;
}

.options-values-table td:nth-child(3),
.options-values-table td:nth-child(4) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.btn-wrapper {
  display: flex;
  justify-content: space-between;
}
.add-option {
  min-width: 222px;
}
.model-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}
.field {
  min-width: 270px;
  max-width: 270px;
}
.init-config-container {
  width: 1170px;
  margin-top: 20px;
}
.fields-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 30px;
  margin-top: 10px;
  border-bottom: 1px solid var(--grey-med-light);
  padding-bottom: 30px;
}
