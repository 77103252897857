.header {
  flex: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 40px;
  overflow: visible;
}
.header h3 {
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  margin: 0;
}
.ag-id span:first-child {
  color: var(--grey);
}

.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}
.body {
  display: flex;
  flex-direction: column;
  padding: 24px 40px 18px 40px;
  height: 100%;
}
.params-wrapper {
  width: 50%;
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}

.tabs {
  width: 50%;
  margin-bottom: 16px;
}
.close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}

.filters {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
}
.filters-field {
  flex: 1;
}
.filters-user {
  max-width: 50%;
}
.filters button {
  margin-top: 20px;
  height: 40px;
}
.list {
  display: flex;
  gap: 40px;
  height: 100%;
}
.list-column {
  flex: 1;
  width: 50%;
  background-color: var(--light-grey);
  border-radius: 12px;
  padding-top: 20px;
}
.list-column header {
  flex: 1;
  font-weight: var(--font-weight-m);
  margin-bottom: 4px;
  margin-left: 20px;
}
.table {
  height: auto;
}
.table th {
  white-space: nowrap;
}
.table td:nth-child(1) {
  width: 1%;
  padding-left: 20px;
  padding-right: 8px;
}
.table th:last-child,
.table td:last-child {
  padding-right: 40px !important;
}
.table tr button {
  opacity: 0;
}
.table tr:hover button {
  opacity: 1;
}
.table tr button:hover path {
  fill: var(--light-black) !important;
}
.table tbody tr:hover {
  background-color: var(--medium-grey) !important;
}
.table svg {
  width: 16px;
}
