.table {
  padding: 0 40px 12px 40px;
  min-height: 220px;
}
.table td:nth-child(2),
.table td:nth-child(1) {
  width: 49%;
}
.table td:nth-child(3),
.table td:nth-child(4) {
  width: 1%;
  padding: 0 0 0 12px !important;
}

.filters article {
  flex: 0.3;
}
