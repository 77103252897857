.paper {
  flex: none !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 40px 20px 40px;
  overflow: visible;
}
.header {
  display: flex;
}
.header h3 {
  margin: 0 16px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 40px;
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}
.fields-wrapper {
  padding: 25px 40px 20px 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.create-field {
  min-width: 270px;
  max-width: 270px;
}
.values-wrapper {
  min-height: 60px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin: 10px 40px;
  padding: 20px 20px;
  background-color: var(--light-grey);
}
.values-wrapper header {
  font-weight: var(--font-weight-m);
  line-height: 20px;
  margin-bottom: 14px;
}
.values-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.value-add-wrapper {
  position: relative;
  gap: 5px;
}
.value-add-wrapper svg {
  position: absolute;
  top: 9px;
  right: 10px;
  cursor: pointer;
}
.value-add-wrapper path {
  fill: var(--grey-dark);
}
.value-add {
  flex: 0;
}
.value-add input {
  width: 100px;
  min-height: 30px;
  max-height: 30px;
  border-radius: var(--radius-xs);
  padding-right: 25px;
  background-color: var(--white);
}
.added-value {
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 30px;
  padding: 0 8px;
  background-color: var(--white);
  border-radius: var(--radius-xs);
}
.added-value svg {
  margin-left: 10px;
}
